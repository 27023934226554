import styled from 'styled-components'


export const MobileNavigation = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 20px;
  width: 90%;
  min-width: 750px;
  margin: 20px auto;
`
export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
export const NavIcon = styled.div`
  background: rgba(108, 185, 153, 0.1);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 35px;
    height: 35px;
  }
`
export const NavName = styled.span`
  color: #125F3F;
  font-weight: 500;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
` 
export const MobileNavDivider = styled.div`
  background: rgba(0,0,0,0.7);
  flex: 0 0 1px;
  height: 30px;
`