import styled from 'styled-components';

export const HomeCards = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 30px;
    max-width: 800px;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: auto;
    
}
`

export const HomeOverall = styled.div`
    position: relative;
    padding: 0px 1rem 0px 1rem;
    width: 100%;
    background-color: ${props => props.bgColor || '#FAFAFA'};
    min-height: 94vh;
    margin: 0px 0 100px 100px;

    @media (max-width: 1023px) {
        margin-left: 0px;
    }

    @media (max-width: 890px) {
        padding: 40px 30px;
    }
    @media (max-width: 530px) {
        padding: 40px 10px;
    }
`

export const HomeNoUser = styled.div`
    width: 100%;
    background-color: #FAFAFA;
    min-height: 94vh;
    margin-left: 96px;

    @media (max-width: 1023px) {
        margin-left: 0px;
    }

    @media (max-width: 890px) {
        padding-top: 40px;
    }
`

export const HomeHeader = styled.div`
    & > *:first-child {
        font-size: 27px;
    }
    color: rgba(0,0,0,0.7);
    font-family: 'Jarkarta';
    font-weight: 400;
    text-align: left;
    
    @media (max-width: 500px) {
        margin-bottom: 15px;
        & > *:first-child {
            font-size: 20px;
        }
    }
`

export const HeadingContainer = styled.div`
    width: 100%;
    text-align: left;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 10px auto;
    }
`

export const SectionContainer = styled.div`
  &:not(&:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 800px) {
    &:not(&:last-child) {
        margin-bottom: 30px;
    }
  }
`
export const TipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #FAFAFA;
  padding: 10px;
  flex: 0 0 500px;

    @media screen and (max-width: 800px) {
        flex: auto;
        padding: 12px 10px;
        font-size: 15px;
    }
`