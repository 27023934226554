import styled from 'styled-components'

export const TopNavigationWrapper = styled.div`
    width: 100%;
    background: #F1F3F4;
    padding-top: 4px;
`

export const TopNavigation = styled.div`
  max-width: 980px;
  height: 35px;
  margin: 10px auto 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 50px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`
export const TopNavItem = styled.a`
  color: rgba(0,0,0,0.7);
  flex: 0 0 auto;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  height: 100%;

  &:first-child {
    color: #125F3F
  }
  &:first-child::after {
    content: "";
    width: 100%;
    height: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: #125F3F;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`
export const TopNavDivider = styled.div`
  background: rgba(0,0,0,0.7);
  flex: 0 0 1px;
  height: 15px;
  margin-bottom: 13px;
`