import Link from 'next/link'
import * as S from './StyledTopNavigation'
import React from 'react'
import { useAuth } from '../../../utils/auth/newAuth'

function TopNavigation() {
    const { profile } = useAuth()
  return (
    <S.TopNavigationWrapper>
        <S.TopNavigation>
            <Link href="/" passHref>
            {
                profile ? 
                <S.TopNavItem>For you</S.TopNavItem> :
                <S.TopNavItem>Home</S.TopNavItem> 
            }
            </Link>
            <Link href="/listings/newest" passHref>
            <S.TopNavItem>
                New Listings
            </S.TopNavItem>
            </Link>
            <Link href="/active-wants" passHref>
            <S.TopNavItem>
                Active Wants
            </S.TopNavItem>
            </Link>
            <S.TopNavDivider />
            <Link href="/listings/Land" passHref>
            <S.TopNavItem>
                Land
            </S.TopNavItem>
            </Link>
            <Link href="/listings/Multifamily" passHref>
            <S.TopNavItem>
                Multifamily
            </S.TopNavItem>
            </Link>
            <Link href="/listings/Retail" passHref>
            <S.TopNavItem>
                Retail
            </S.TopNavItem>
            </Link>
            <Link href="/listings/Sale-of-Business" passHref>
            <S.TopNavItem>
                Businesses
            </S.TopNavItem>
            </Link>
            <Link href="/listings/Industrial" passHref>
            <S.TopNavItem>
                Industrial
            </S.TopNavItem>
            </Link>
            <Link href="/listings/Office" passHref>
            <S.TopNavItem>
                Office
            </S.TopNavItem>
            </Link>
        </S.TopNavigation>
    </S.TopNavigationWrapper>
  )
}

export default TopNavigation