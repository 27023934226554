import { Box, Skeleton, Flex } from "@chakra-ui/react";
import NextImage from "next/image";
import React, { useEffect, useState } from "react";
import { storage } from "../../src/config/firebase";
import placeholder from '../../public/images/placeholder-image.svg'
import { RelevantImg, RelevantListingBox, SubjectLine } from "./ListingCardStyles";
import NumberFormat from "react-number-format";
import * as Sentry from '@sentry/nextjs'
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { clientError } from "../../utils/utilties";

const RelevantListing = (props) => {
    const [imageLoadStatus, setImageLoadStatus] = useState('loading');
    const { price, priceType, type, city, subject, id, primaryImg, imageOrder, imagesFresh, imageURLs } = props;
    const [imageURL, setImageURL] = useState(imagesFresh && imageURLs?.length > 0 ? imageURLs[0] : null)


    // Wants will have area as an object { 

    String.prototype['toProperCase'] = function () {
        return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };


    const functionv2 = async () => {
        if (imageURLs?.length > 0 && imagesFresh) {
            setImageURL(imageURLs[0])
        }

        else {

            const listingRef = ref(storage, `listingImages/${id}/compressed`);
            let periodIndex = primaryImg?.lastIndexOf(".")
            let primaryImageName = primaryImg?.substring(0, periodIndex);
            let primaryImageExt = primaryImg?.substring(periodIndex);

            setImageLoadStatus('loading')

            let returnedFiles = await listAll(listingRef);
            let returnedItems = returnedFiles?.items

            // let localPrimary

            if (returnedItems?.length > 0) {

                if (imageOrder?.length > 0) {

                    // Go through every item in the image order and add it to the array

                    let f = imageOrder[0];

                    returnedItems.filter(it => {
                        const nameRef = ref(storage, `listingImages/${id}/compressed/${it.name}`)
                        if (it.name.split(/\.(?=[^\.]+$)/)[0] === f?.split(/\.(?=[^\.]+$)/)[0] + '_700x700' || it.name.split(/\.(?=[^\.]+$)/)[0] === f?.split(/\.(?=[^\.]+$)/)[0]) {
                            getDownloadURL(nameRef).then(url => {
                                setImageURL(url)
                                setImageLoadStatus('success')
                            }).catch(err => {

                                clientError(err)
                                setImageLoadStatus('success')
                                setImageURL(placeholder)
                            })
                        }
                    })
                }

                else {
                    // Multiple photos
                    let imagePromises = returnedItems.reduce((accumulator, currentValue) => {
                        if (currentValue.name.includes("_700x700")) { //loading up only x700 files

                            if (currentValue.name == `${primaryImageName}_700x700${primaryImageExt}`) { // if image isn't the primary image
                                getDownloadURL(currentValue).then(url => {
                                    setImageURL(url)
                                    setImageLoadStatus('success')
                                }).catch(err => {
                                    clientError(err)
                                    setImageLoadStatus('success')
                                    setImageURL(placeholder)
                                }) // move it to the initial point of the array
                            }

                        }
                        return accumulator;
                    }, []);

                }
            }

            else {
                setImageURL(placeholder)
                setImageLoadStatus('success')
                return
            }
        }

    }

    // Count impression
    useEffect(() => {
        if (!imageURL) {

            functionv2();
        }
    }, [id])


    return (
        <RelevantListingBox>
            <RelevantImg>
                {imageURL &&
                    <NextImage
                        // objectFit="cover"
                        src={imageURL}
                        // fallbackSrc={placeholder}
                        alt={subject}
                        width={333.367}
                        height={200}
                        layout="responsive"
                    />
                }

                <Skeleton
                    isLoaded={imageURL}
                    height="100%"
                    width="100%"
                />

            </RelevantImg>

            <Box
                p="15px"
            >
                <SubjectLine>
                    {subject.toProperCase()}
                </SubjectLine>

                <Flex alignItems="baseline" color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                    marginBottom="2px"
                // isTruncated={postType === "Want" ? true : false}
                >
                    {/* <GrLocationPin color="gray" size="12px" /> */}

                    {city}

                </Flex>


                <Box>

                    {type === 'For Sale' &&
                        <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }

                    {type === 'For Lease' &&
                        <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'/' + priceType} />
                    }
                </Box>
            </Box>
        </RelevantListingBox>
    );
};

export default RelevantListing;