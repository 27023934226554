import styled from 'styled-components';
export const SectionSignup = styled.div`
  /* CSS styles for the section */
`;

export const Container = styled.div`
  /* CSS styles for the container */
`;

export const Card = styled.div`
  /* CSS styles for the card */
  border: 1px solid #dcdcdc;
    border-radius: 4px;
    text-decoration: none;
    transition: all .2s;
    margin-bottom: 24px;
`;

export const CardContent = styled.div`
  /* CSS styles for the card content */
  padding: 24px;
`;

export const SignupFormWrap = styled.div`
  /* CSS styles for the signup form wrap */
  align-items: center;
    display: flex;

    @media screen and (max-width: 767px) {
      display: block;
    }

`;

export const HSignupForm = styled.div`
  /* CSS styles for the signup form */
  flex: 1;
    margin-bottom: 0;
    /* margin: 0 0 15px; */
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
`;

export const Form = styled.form`
  /* CSS styles for the form */
  display: flex;

  @media screen and (max-width: 767px) {
      display: block;
    width: 100%;
    }
`;

export const Field = styled.input`
  /* CSS styles for the email field */
  margin-right: 16px;
  width: 100%;
    height: 38px;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.42857;
    display: block;
  height: auto;
    color: #000;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    margin-bottom: 0;
    padding: 12px 1em;
    font-size: 16px;
    line-height: 24px;
    transition: border .3s;

    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
      margin-right: 0;
    }
`;

export const SubmitButton = styled.button`
  /* CSS styles for the submit button */
  color: #fff;
    background-color: #125f3f;
    border: 1px solid #125f3f;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    transition: all .15s;
    display: block;
`;

export const FormDone = styled.div`
  /* CSS styles for the form success message */
`;

export const FormFail = styled.div`
  /* CSS styles for the form failure message */
`;

export const OrText = styled.div`
  /* CSS styles for the "or" text */
  color: #777;
  margin-right: 16px;

  @media screen and (max-width: 767px) {
    text-align: center;
    padding-bottom: 4px;
    padding-top: 4px;
    margin-right: 0;
    font-size: 14px;
  }
`;

export const GoogleButton = styled.a`
  /* CSS styles for the Google sign up button */
    border: 1px solid #000;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    transition: all .15s;
    display: inline-block;
    height: 48px;
    padding-top: .7em;
    padding-bottom: .7em;
    color: #000;
    background-color: #fff;
    border-color: #d4d4d4;
`;

export const FlexHSplit = styled.div`
  /* CSS styles for the flex-h-split container */
  justify-content: flex-end;
  display: flex;
`;

export const TextSecondary = styled.div`
  /* CSS styles for the secondary text */
`;

export const LinkSecondary = styled.a`
  /* CSS styles for the secondary link */
`;

export const ButtonIconText = styled.div`
display: flex;
`