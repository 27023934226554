import { useState, useRef, useEffect } from 'react'
import ListingCard from '../ListingCard/ListingCard2point0'
import ReactElasticCarousel from 'react-elastic-carousel'
import ActiveBuyerCard from '../ListingCard/ActiveBuyerCard2'
import { MdArrowForwardIos, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowBackIosNew } from 'react-icons/md'
import useWindowSize from '../Utility/windowSize'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import styled, { css } from 'styled-components'
import RelevantListing from '../ListingCard/RelevantListing'
import Link from 'next/link'
import slugify from 'slugify'
import { ImArrowRight2 } from 'react-icons/im'

interface SwipeableCardsProps {
  title?: string
  items: any[]
  type: 'listings' | 'wants' 
  listingCard?: 'primary' | 'secondary'
  scrollOnXAxis?: number
  showMoreItemsBtn?: boolean
  moreItemsLink?: string
}
  
export const SwipeableCards = (props: SwipeableCardsProps) => {
      if (props.showMoreItemsBtn && !props.moreItemsLink) {
        throw Error("'moreItemsLink' prop missing from SwipeableCards, but is necessary when using 'showMoreItemsBtn' prop")
      }
      const carouselRef = useRef(null)
      const [currentIndex, setCurrentIndex] = useState(0)
      const size = useWindowSize()
  
      const PrevBtn = ({ isDisabled }) => (
        <IconButton 
          aria-label='Show previous slide'
          variant={'link'}
          icon={
            <MdKeyboardArrowLeft
            />
          }
          color={isDisabled ? "#ddd" : "#000"}
          disabled={isDisabled}
          onClick={carouselRef.current?.slidePrev}
        />
      )
      const NextBtn = ({ isDisabled }) => (
        <IconButton 
          aria-label='Show next slide'
          variant={'link'}
          icon={
            <MdKeyboardArrowRight
            />
          }
          color={isDisabled ? "#ddd" : "#000"}
          disabled={isDisabled}
          onClick={carouselRef.current?.slideNext}
        />
      )
  
      const carouselBreakpoints = [
      { width: 1, itemsToShow: 1, itemsToScroll: 1 },
        { width: 815, itemsToShow: 3, itemsToScroll: 3 },
        { width: 1500, itemsToShow: 5, itemsToScroll: 5 },
      ];
      const onNextPopStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
      };
      const onPrevPopStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(props.items.length);
        }
      };
      const scrollItemsOnXAxis = size.width < 800;
      const noOfItemsOnSlide = 
      !!carouselRef.current ?
        carouselRef.current.sliderContainer.offsetWidth < 1200 ? 3 : 
          carouselRef.current.sliderContainer.offsetWidth < 1350 ? 4 : 5 :
        1
      
  
      return (
            <Box
              position={'relative'}
            >
              <Flex
                  justifyContent={'space-between'}
                  alignItems="flex-end"
                  mb="8px"
                  pr='25px'
              >
                {
                  props.title ? (
                    <Flex justifyContent="center" alignItems='flex-end' columnGap="25px">
                      <SectionTitle>{props.title}</SectionTitle>
                      {
                        props.showMoreItemsBtn && (
                          <Link href={props.moreItemsLink} passHref>
                            <a>
                              <MoreItemsBtn>
                                <span>View more</span>
                                <ImArrowRight2 />
                              </MoreItemsBtn>
                            </a>
                          </Link>
                        )
                      }
                    </Flex>
                  ) : <p></p>
                }
                  <Flex
                      // flex="0 0 160px"
                      // maxWidth="320px"
                      justifyContent={'space-evenly'}
                      alignItems='center'
                      columnGap={'10px'}
                  >
                      {/* <PrevBtn isDisabled={false} /> */}
                      {
                        scrollItemsOnXAxis || (props.items.length / noOfItemsOnSlide) <= 1 ? 
                          <></> :
                          new Array(Math.floor(props.items.length / noOfItemsOnSlide)).fill(null).map((_, index) => (
                              <Indicator key={index} active={Math.floor(currentIndex / noOfItemsOnSlide) === index} />
                          ))
                      }
                      {/* <NextBtn isDisabled={false} /> */}
                  </Flex>
              </Flex>
              {
                scrollItemsOnXAxis ?
                  <ScrollableItems 
                    listingCard={props.listingCard}
                  >
                    {
                      props.type === 'listings' ?
                        props.listingCard === 'secondary' ?
                          props.items.map((item, index) => (
                            <Link
                              key={item.id+index}
                              passHref
                              href={`/listings/${encodeURIComponent(item.id)}/${slugify(
                                item.subject.toProperCase()
                              )}`}
                            >
                              <a>
                                <RelevantListing {...item} />
                              </a>
                            </Link>
                            // <SecondaryListingCardWrapper key={index}>
                            // </SecondaryListingCardWrapper>
                          )) :
                          props.items.map((item, index) => (
                            <ListingCard 
                              {...item}
                              key={item.id+index}
                              noSeparator={true}
                            />
                          )) 
                      :
                      props.items.map((item, index) => (
                        <Link href={`https://zonado.com/active-wants/${encodeURIComponent(
                          item.id
                        )}/${slugify(
                          item.subject.toProperCase()
                        )}`} passHref>
                          <a>
                            <ActiveBuyerCard 
                              {...item}
                              key={item.id+index}
                              hideModifyButtons
                              isCollapsed
                            />
                          </a>
                        </Link>
                      ))
                    }
                  </ScrollableItems> :
                  <CarouselWrapper
                    listingCard={props.listingCard}
                  >
                      <ReactElasticCarousel
                          ref={carouselRef}
                          isRTL={false}
                          // breakPoints={carouselBreakpoints}
                          itemsToShow={noOfItemsOnSlide}
                          itemsToScroll={noOfItemsOnSlide}
                          initialActiveIndex={0}
                          showArrows={true}
                          pagination={false}
                          onPrevStart={onPrevPopStart}
                          onNextStart={onNextPopStart}
                          onChange={({ index, object }) => {
                              setCurrentIndex(index)
                          }}
                          renderArrow={({ type, onClick }) => (
                            <>
                              {
                                type === "PREV" && currentIndex !== 0 ?
                                <Flex 
                                  alignItems={"center"}
                                  position="absolute"
                                  top="50%"
                                  zIndex={2}
                                  left={type === "PREV" ? "-15px" : "unset"}
                                >
                                  <IconButton
                                    isRound
                                    fontWeight="500"
                                    variant="solid"
                                    color={"white"}
                                    bg="rgba(18, 95, 63, 0.7)"
                                    aria-label={`Go to previous slide`}
                                    _hover={{
                                      backgroundColor: 'rgba(18, 95, 63, 0.7)'
                                    }}
                                    icon={
                                      <MdOutlineArrowBackIosNew />
                                    }
                                    onClick={onClick}
                                  />
                                </Flex> : 
                                type === "NEXT" && props.items.length > noOfItemsOnSlide &&
                                <Flex 
                                  alignItems={"center"}
                                  position="absolute"
                                  top="50%"
                                  zIndex={2}
                                  right={type === "NEXT" ? "-15px" : "unset"}
                                >
                                  <IconButton
                                    isRound
                                    fontWeight="500"
                                    variant="solid"
                                    color={"white"}
                                    bg="rgba(18, 95, 63, 0.7)"
                                    aria-label={`Go to next slide`}
                                    _hover={{
                                      backgroundColor: 'rgba(18, 95, 63, 0.7)'
                                    }}
                                    icon={
                                      <MdArrowForwardIos />
                                    }
                                    onClick={onClick}
                                  />
                                </Flex>
                              }
                            </>
                          )}
                      >
                          {
                            props.type === 'listings' ?
                              props.listingCard === 'secondary' ?
                                props.items.map((item, index) => (
                                  <Link
                                    key={item.id+index}
                                    passHref
                                    href={`/listings/${encodeURIComponent(item.id)}/${slugify(
                                      item.subject.toProperCase()
                                    )}`}
                                  >
                                    <a>
                                      <RelevantListing {...item} />
                                    </a>
                                  </Link>
                                  // <SecondaryListingCardWrapper key={index}  >
                                  // </SecondaryListingCardWrapper>
                                )) :
                                props.items.map((item, index) => (
                                  <ListingCard 
                                    {...item}
                                    key={item.id+index}
                                    noSeparator={true}
                                  />
                                  // <ListingCardWrapper key={index}>
                                  // </ListingCardWrapper>
                                )) 
                            :
                            props.items.map((item, index) => (
                              <Link href={`https://zonado.com/active-wants/${encodeURIComponent(
                                item.id
                              )}/${slugify(
                                item.subject.toProperCase()
                              )}`} passHref>
                                <a>
                                  <ActiveBuyerCard 
                                    {...item}
                                    key={item.id+index}
                                    hideModifyButtons
                                    isCollapsed
                                  />
                                </a>
                              </Link>
                            ))
                          }
                      </ReactElasticCarousel>
                  </CarouselWrapper>
              }
            </Box>
      )
  }
  
  // #region - Styled components  
  const Indicator = styled.div`
      height: 2px;
      flex: 0 0 10px;
      background-color: ${props => props.active ? 'green' : "#bbb"};
  `
  const ScrollableItems = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 10px;

    & > * {
      margin: 0 0 20px 0;
      padding: 0px;
      min-width: 300px;
      width: 300px;
    }

    ${
      ({ listingCard }) => {
        if (listingCard === 'secondary') {
          return css`
            column-gap: 30px;
            & .ListingCardStyles__RelevantListingBox-sc-63fab291-13 {
              height: 300px;
            }
            & > * {
              min-width: 200px;
              width: 200px;
            }
          `
        }
      }
    }

    & .ActiveBuyerCardStyles__OuterCard-sc-248553d7-1 {
      height: 200px;
    }

    // & .ListingCard2point0Styles__Overall-sc-bbe86a9-0 {
    //   padding-left: 0px;
    // }
    // & .ListingCardStyles__RelevantListingBox-sc-63fab291-13 {
    //   width: auto;
    // }
  `
  const CarouselWrapper = styled.div`
      & .rec-slider-container {
        margin: 0;
      }
        
      & .rec.rec-carousel {
        position: relative;
          
      }

      & .rec-swipable {
        // justify-content: flex-start;
      }
      & .rec-swipable .rec-item-wrapper {
        flex: 1 1 auto;
        max-width: 400px;
      }

      & .rec-item-wrapper > *  {
        padding-left: 0px;
        padding-right: 20px;
      }
      & .ListingCardStyles__RelevantListingBox-sc-63fab291-13 {
        margin: 0;
        min-width: 200px;
      }

      & .ActiveBuyerCardStyles__OuterCard-sc-248553d7-1 {
        margin-right: 15px;
        height: 200px;
      }

      ${
        ({ listingCard }) => {
          if (listingCard === 'secondary') {
            return css`
              & .rec.rec-item-wrapper {
                height: 100%;
                width: auto;
              }
            `
          }
        }
      }
  `
  const SectionTitle = styled.p`
    font-size: 20px;
    color: rgba(0,0,0,0.7);
    font-family: 'Jarkarta';
    font-weight: 400;

    @media screen and (max-width: )
  `

  const MoreItemsBtn = styled.button`
    display: flex;
    color: #125F3F;
    justify-content: center;
    align-items: center;
    column-gap: 9px;

    & > *:last-child {
      margin-top: 2px;
    }
  `
  // #endregion