import Head from 'next/head'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import SideNav from './SideNav/SideNav'
import { getToken } from '../src/config/firebase'
import { HeadingContainer, HomeHeader, HomeOverall, SectionContainer, TipContainer } from '../styles/HomeStyles'
import { useAuth } from '../utils/auth/newAuth'
import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import { FcIdea } from 'react-icons/fc'
import { SwipeableCards } from './SwipeableCards/SwipeableCard'
import { SpinnerHolder } from '../styles/ListingsStyles'
import TopNavigation from './Landing/TopNavigation'
import useWindowSize from './Utility/windowSize'
import MobileNavigation from './Landing/MobileNavigation'
import { QueryStateContext } from '../utils/contexts/QueryStateContext'

function Home() {
    const [sectionsData, setSectionsData] = useState([])
    const { profile, userId } = useAuth()
    const size = useWindowSize()
    const toast = useToast()
    const {
    recordingURL
    } = useContext(QueryStateContext);
    const tips = [
      "Respond quickly to your messages to get the 'Responsive' badge next to your name on your listings",
      "Looking for a specific type of property? Post a 'Want' by clicking 'Active Wants' on the left panel.",
      "You can now send files directly through Zonado's messaging system. All formats, no limits.",
      "You can now see Read Receipts on messaging. Know when someone has already read your message or if it's still unread.",
      "You can change your industry role, brokerage, and other information in your profile settings.",
    ]

    const currentTip: string = useMemo(() => {
      const tipIndex = Math.floor(Math.random() * tips.length)
      return tips[tipIndex]
    }, [])

    async function homeListingsQuery () {
        const result = await fetch('/api/home', {
            method: 'GET',
            headers: {
                'X-LogRocket-URL': recordingURL,
                "Content-Type": "application/json",
                token: await getToken()
            }
        })
        const { data } = await result.json();
        return data
    } 

    const {
      status: loadingStatus,
      refetch
    } = useQuery('Fetch listings in home.tsx', homeListingsQuery, {
        enabled: profile ? true : false,
        refetchOnWindowFocus: false,
        onSuccess(data) {
          setSectionsData(data)
        },
        onError(error) {
          console.log(error)
          toast({
            title: "Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
    })

    // useEffect(() => {
    //   if (profile) {
    //     refetch()
    //   }
    // }, [profile])

    if (loadingStatus === 'loading') {
      return (
        <Box mt="60px">
          <SpinnerHolder>
            <Spinner size="xl" color="green.800" />
          </SpinnerHolder>
        </Box>
      )
    }

    return (
        <>
            <Head>
                <title>Home</title>
                <link rel="icon" href="/favicon.ico" />
                <meta name="robots" content="noindex" />
            </Head>
            <SideNav />
            <HomeOverall bgColor={'#fff'}>
              <Box 
                // position={'absolute'}
                // top="0"
                // left="0"
                width="100%"
                zIndex="2"
                overflowX="auto"
              >
                {
                  size.width > 1190 ?
                  <TopNavigation /> :
                  <MobileNavigation />
                }
              </Box>
                <HeadingContainer>
                    <HomeHeader>
                        {
                            !!profile && (
                              <>
                                <Text>Hello, {profile?.firstName}</Text>
                                <Text>Welcome to your personalized dashboard</Text>
                              </>
                            )
                        }
                    </HomeHeader>
                    <TipContainer>
                        <Box flex={'0 0 40px'}>
                          <FcIdea size="20px" />
                        </Box>
                        <Text m={0} p={0}>
                            <strong>Tip: </strong> {currentTip}
                        </Text>
                    </TipContainer>
                </HeadingContainer>

                <Box mt="20px">
                  {
                    loadingStatus === 'success' && sectionsData?.length &&
                    (
                      sectionsData.map((section, index) => (
                        <SectionContainer key={section.title}>
                          <SwipeableCards 
                            title={section.title} 
                            type={section.type} 
                            items={section.items}
                            moreItemsLink={section.title === 'Recently Added' && `/listings`}
                            showMoreItemsBtn={section.title === 'Recently Added'}
                          />
                        </SectionContainer>
                      ))
                    )
                  }
                </Box>
                
            </HomeOverall>
        </>
    )
}

export default Home

// #region - Styled components

// #endregion