export const smartMatchFAQ = [
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Will my information be shared with everyone?",
        body: "Definitely not. Those who have a listing that matches your needs will message you on the platform. Based on your interest, you can decide to share your contact information with them or not."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Is posting a want free?",
        body: "Absolutely! While we do have some Pro plans, posting a want is absolutely free."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Who can post a want?",
        body: "Anyone – from independent investors and buyers to industry professionals. If you are in the market to buy Commercial Real Estate or a business, you can post a want in 3 simple steps."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "What if I am already working with an Agent?",
        body: "No problem! Feel free to list your property/want on Zonado regardless of you being represented by a Realtor or not. Listing on Zonado isn't impacted or conflicted by a Realtor agreement. "
    },
]

export const plansFAQ = [
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Do I get all the leads sent to me directly? ",
        body: "Yes. Your leads are sent directly to you. Zonado does not, and will never engage in selling this data to others."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How long does my listing stay up?",
        body: "Your listing is kept on Zonado until it is marked sold. No time limits - we go the distance with you."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Will Zonado charge me a commission or fee for selling?",
        body: "Definitely not. We have optional Pro plans if you choose to upgrade but apart from that, we have no additional/hidden fees."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How much does Zonado cost?",
        body: "Zonado basic is free for all users. To access premium features, including adding listings, we offer a PRO plan where prices vary based on monthly or annual billing cycle. Further, if you’d like to maximize exposure for a particular listing, please email us at support@zonado.com and someone from our team will be happy to assist you."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "What forms of payment do you accept?",
        body: "We accept Visa, Mastercard, American Express, and JCB payments from customers worldwide"
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Do you offer any Brokerage discounts?",
        body: "Yes! Contact us at support@zonado.com and we will be happy to assist"
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How secure is Zonado?",
        body: "Protecting the data you trust to Zonado is our first priority. Zonado uses physical, procedural, and technical safeguards to preserve the integrity and security of your. We back up your data to prevent data loss and aid in recovery."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Will I be charged sales tax?",
        body: "Yes, at the final check out we will apply provincial and national sales tax based on your billing address to your total charge."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How do I cancel my subscription?",
        body: "It's easy to cancel your subscription. To cancel a paid plan, please send us an email to support@zonado.com with the email address associated with your paid plan and we will have it cancelled. After cancelling, when you reach your plan renewal, you will then be able to continue to use Zonado Basic."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "What is your refund policy?",
        body: "We do not offer refunds. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Is my Pro membership transferable?",
        body: "Sure, send us a note at support@zonado.com with the email you would like it transferred to and we will be happy to assist you."
    },
]

export const homeFAQ = [

    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Do I get all the leads sent to me directly? ",
        body: "Yes. Your leads are sent directly to you. Zonado does not, and will never engage in selling this data to others."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How long does my listing stay up?",
        body: "Your listing is kept on Zonado until it is marked sold. No time limits - we go the distance with you."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Will Zonado charge me a commission or fee for selling?",
        body: "Definitely not. We have optional Pro plans if you choose to upgrade but apart from that, we have no additional/hidden fees."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How much does Zonado cost?",
        body: "Zonado basic is free for all users. To access premium features, we have a PRO plan where prices vary based on monthly or annual billing cycle. Further, if you’d like to maximize exposure for a particular listing, please email us at support@zonado.com and someone from our team will be happy to assist you."
    },

    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How secure is Zonado?",
        body: "Protecting the data you trust to Zonado is our first priority. Zonado uses physical, procedural, and technical safeguards to preserve the integrity and security of your. We back up your data to prevent data loss and aid in recovery."
    },
   
]

export const contactFaq = [
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Where is Zonado based out of?",
        body: "Zonado is a Canadian start-up with its office based in Toronto"
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "How did Zonado come to be? ",
        body: <>
            Great question! <br /><br />
            Check out the story on the initial phase of our journey here
            (<b><a style={{ color: "#125F3F !important", fontStyle: "italic" }} target="_blank" href="https://www.linkedin.com/pulse/how-zonado-came-harjaap-singh-makkar/">Link</a></b>
            )</>
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Who can use Zonado?",
        body: "Zonado is open to all professionals and independent buyers and sellers in the Commercial Real Estate and Sale of Business industry in Canada."
    },
]

export const realtorsFAQ = [
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Do I get all the leads sent to me directly? ",
        body: "Yes. Your leads are sent directly to you. Zonado does not, and will never engage in selling this data to others."
    }
]

export const professionalFaq = [

    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Do I get all the leads sent to me directly?",
        body: "Yes. Your leads are sent directly to you. Zonado does not, and will never engage in selling this data to others."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "What if I am already working with a broker or realtor?",
        body: "Having a Broker/Agent representation is no problem. You can still list your business here for added exposure and reach the right buyer group."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Is Zonado a brokerage? ",
        body: "Zonado is an independent platform that brings together buyers and sellers of Businesses and Commercial Real Estate. We are not a brokerage. "
    },
]

export const investorFaq = [
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Is my private information safe?",
        body: "Absolutely. At Zonado, we are big on information and data protection. Your contact information will only be shared if you initiate a chat showing interest in a listing. Until then, explore opportunities without any risk of giving away private information."
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "Would I be able to contact the Listing Agent/Seller directly?",
        body: "For sure. If you come across a listing you are interested in, message the listing representative and be in touch with the right person in seconds.  "
    },
    {
        id: `${String(Math.random()).replace(".", "_")}`,
        title: "What is this NDA I am being asked to sign?",
        body: "Some listing representatives may require you to sign a Non Disclosure Agreement before sharing the listing details with you. This is standard paperwork required to keep their client’s information safe and protected."
    },
]