import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { BsListStars } from 'react-icons/bs'
import { GiOfficeChair } from 'react-icons/gi'
import * as S from './StyledMobileNavigation'

function MobileNavigation() {
  return (
    <S.MobileNavigation>
        <Link href="/listings/newest">
        <a>
            <S.NavItem>
            <S.NavIcon>
                <BsListStars 
                    color="#125F3F"
                    size="22px"
                />
            </S.NavIcon>
            <S.NavName>New listings</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/active-wants" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/active_wants.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Active Wants</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <S.MobileNavDivider />
        <Link href="/listings/Land" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/Land.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Land</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/listings/Multifamily" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/Multifamily.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Multifamily</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/listings/Retail" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/Retail.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Retail</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/listings/Sale-of-Business" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/Business.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Business</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/listings/Industrial" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <Image
                src="/images/newLanding/navigationIcons/Industrial.svg"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Industrial</S.NavName>
            </S.NavItem>
        </a>
        </Link>
        <Link href="/listings/Office" passHref>
        <a>
            <S.NavItem>
            <S.NavIcon>
                <GiOfficeChair
                color="#125F3F"
                width="22px"
                height="22px"
                />
            </S.NavIcon>
            <S.NavName>Office</S.NavName>
            </S.NavItem>
        </a>
        </Link>
    </S.MobileNavigation>
  )
}

export default MobileNavigation